button,
input,
optgroup,
select,
textarea,
body,
html {
    font-family: 'Kanit', sans-serif !important;
    background: #F4F4F4;
}

div {
    text-align: left;
}

label {
    cursor: pointer;
}

.ui.button,
.ui.header,
.message,
.content,
.header,
h1,
h2,
h3 {
    font-family: 'Kanit', sans-serif !important;
}

.btn-danger {
    background: orange !important;
}

.wetv_bg {
    /*background: #fbe504;*/
    background: #eca800;
    min-height: 100%;
    width: 100%;
    padding: 6rem 1rem 0;
}

.full-bg {
    min-height: 100%;
    width: 100%;
    padding-bottom: 1rem;
}

.full-bg.padded {
    /*padding: 1rem;*/
    padding: 6rem 1rem 1rem;
}

#root {
    height: 100%;
    width: 100%;
}

.card-center {
    margin: auto !important;
}

.form-group {
    margin-bottom: 1rem;
}

.input-margin-right {
    margin-right: 5px;
}

#title-upload-button {
    font-size: 14px;
    font-family: 'Kanit', sans-serif !important;
}

.grey-bg {
    background: #F4F4F4 !important;
}

.lg-font {
    font-size: 1.5rem;
}

.md-font {
    font-size: 1.2rem;
}

.custom-title {
    padding: 0.5rem !important;
    font-size: 16px !important;
    background: rgba(48, 59, 70, 0.82) !important;
}

.black-font {
    color: #1b1c1d;
}

.red-font {
    color: red;
}

.green-font {
    color: green;
}

.pull-bottom {
    top: 100%;
    position: absolute;
}

.bank-logo {
    width: 100%;
    max-width: 40px;
    position: relative;
    top: 0;
    vertical-align: text-top;
}

.Upload_root > div {
    text-align: left;
}

.menu {
    position: relative;
}

.menu .sign-out {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
}

.menu .sign-out > div {
    padding: 5px;
    font-size: 20px;
    cursor: pointer;
}

.payment-push-bottom {
    margin-bottom: 2rem !important;
}

.triangle-bottom {
    z-index: 1;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #1b1c1d transparent transparent transparent;
}

.wetv-btn {
    background: #eca800 !important;
    color: #fff !important;
    font-weight: 100;
    letter-spacing: 1px;
}


.wetv-btn:hover {
    background: #eaae00 !important;
}

.oval {
    border-radius: 25px !important;
}

.ui.basic.button.wetv-btn,
.ui.basic.buttons.wetv-btn .button {
    border: 1px solid #eca800 !important;
    color: #eca800 !important;
    box-shadow: none !important;
}

.ui.basic.button.wetv-btn:hover,
.ui.basic.buttons.wetv-btn:hover .button {
    border-color: #eaae00 !important;
    color: #eaae00 !important;
}

.header-customer-title {
    /*background: linear-gradient(135deg, rgba(251, 230, 4, 1) 0%, rgba(251, 230, 4, 1) 50%, rgba(251, 190, 8, 1) 50%, rgba(251, 190, 8, 1) 100%);*/
    background: linear-gradient(135deg, rgba(220, 164, 0, 1) 0%, rgba(220, 164, 0, 1) 50%, rgba(234, 176, 0, 1) 50%, rgba(234, 176, 0, 1) 100%);
    height: 160px;
    width: 100%;
    color: #ffffff;
    padding: 40px 10px 0;
    /*text-shadow: 1px 1px #0000007a;*/
    position: relative;
    top: 4rem;
}

.header-customer-title > .title {
    font-size: 1.5rem;
}

.header-customer-title > .sub-title {
    font-size: 1rem;
}

.pull-top {
    margin-top: -3rem !important;
}

.push-top {
    margin-top: 1rem;
}

.hidden {
    display: none !important;
}

.custom-segment {
    /*margin: 5px 0 !important;*/
    overflow: hidden;
    display: block;
    z-index: 10;
    position: relative;
    /*margin: 10px 0 0 !important;*/
    /*box-shadow: 02px 3px 4px -2px rgba(0, 0, 0, 0.3) !important;*/
    box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.2) !important;
}

.no-margin {
    margin: 0 !important;
}

.no-padded {
    padding: 0 !important;
}

.custom-segment > label:hover {
    background: #f9f9f9;
}

.custom-segment:active {
    background: #ffffff;
}

.segment-details {
    flex: 1;
    padding: 1.3rem 1rem 1rem;
    border: 2px solid rgba(34, 36, 38, .15);
    border-bottom: 0;
    border-left: 0;
    border-radius: 0 6px 6px 0;
}

.segment-icon {
    width: 30px;
}

.segment-icon .ui.checkbox {
    width: 15px
}

.segment-icon-bg {
    background: #cccccc;
    height: 100%;
    width: 100%;
}

.custom-segment.warning .segment-icon-bg {
    background: #eca800;
}

.custom-segment.success .segment-icon-bg {
    background: #21ba45;
}

.custom-segment.normal .segment-icon-bg {
    background: rgba(48, 59, 70, 0.82);

}

.custom-segment.danger .segment-icon-bg {
    background: #db2828;
}

.segment-details .status {
    font-size: 0.9rem;
}

.custom-segment.warning .segment-details .status {
    color: #eca800;
}

.custom-segment.success .segment-details .status {
    color: #21ba45;
}

.custom-segment.danger .segment-details .status {
    color: #db2828;
}

.btn-lg {
    font-size: 1.1rem !important;
}

/*CHECK BOX*/
.custom-segment .ui.checkbox input[disabled] ~ .box:before,
.custom-segment .ui.checkbox input[disabled] ~ label:before,
.custom-segment .ui.checkbox input[readonly] ~ .box:before,
.custom-segment .ui.checkbox input[readonly] ~ label:before,
.tabs-info .ui.checkbox input[disabled] ~ .box:before,
.tabs-info .ui.checkbox input[disabled] ~ label:before,
.tabs-info .ui.checkbox input[readonly] ~ .box:before,
.tabs-info .ui.checkbox input[readonly] ~ label:before,
.custom-segment .ui.disabled.checkbox .box:before,
.custom-segment .ui.disabled.checkbox label:before {
    /*background: #eca800 !important;*/
    border-color: #eca800 !important;
    /*background: #cccccc !important;*/
    /*border-color: #cccccc !important;*/
}

.custom-segment .ui.checkbox input[disabled] ~ .box:after,
.custom-segment .ui.checkbox input[disabled] ~ label:after,
.custom-segment .ui.checkbox input[readonly] ~ .box:after,
.custom-segment .ui.checkbox input[readonly] ~ label:after,
.tabs-info .ui.checkbox input[disabled] ~ .box:after,
.tabs-infosegment .ui.checkbox input[disabled] ~ label:after,
.tabs-info-segment .ui.checkbox input[readonly] ~ .box:after,
.tabs-info .ui.checkbox input[readonly] ~ label:after,
.custom-segment .ui.disabled.checkbox .box:after,
.custom-segment .ui.disabled.checkbox label:after {
    color: #ffffff !important;
}

.custom-segment .ui.checkbox .box:before,
.custom-segment .ui.checkbox label:before,
.custom-segment .ui.checkbox input:checked ~ .box:before,
.custom-segment .ui.checkbox input:checked ~ label:before,
.custom-segment .ui.checkbox input:focus ~ .box:before,
.custom-segment .ui.checkbox input:focus ~ label:before,
.tabs-info .ui.checkbox .box:before,
.tabs-info .ui.checkbox label:before,
.tabs-info .ui.checkbox input:checked ~ .box:before,
.tabs-info .ui.checkbox input:checked ~ label:before,
.tabs-info .ui.checkbox input:focus ~ .box:before,
.tabs-info .ui.checkbox input:focus ~ label:before {
    border-color: #eca800;

}

.custom-segment .ui.checkbox input:checked ~ .box:before,
.custom-segment .ui.checkbox input:checked ~ label:before,
.tabs-info .ui.checkbox input:checked ~ .box:before,
.tabs-info .ui.checkbox input:checked ~ label:before {
    background: #eca800;
}

.custom-segment .ui.checkbox input:checked ~ .box:after,
.custom-segment .ui.checkbox input:checked ~ label:after,
.tabs-info .ui.checkbox input:checked ~ .box:after,
.tabs-info .ui.checkbox input:checked ~ label:after {
    color: #ffffff;
}

.custom-segment .ui.radio.checkbox input:checked ~ .box:after,
.custom-segment .ui.radio.checkbox input:checked ~ label:after,
.tabs-info .ui.radio.checkbox input:checked ~ .box:after,
.tabs-info .ui.radio.checkbox input:checked ~ label:after {
    background-color: #eca800;
}

.custom-segment .ui.checkbox .box:after,
.custom-segment .ui.checkbox label:after,
.custom-segment .ui.checkbox .box:before,
.custom-segment .ui.checkbox label:before,
.tabs-info .ui.checkbox .box:after,
.tabs-info .ui.checkbox label:after,
.tabs-info .ui.checkbox .box:before,
.tabs-info .ui.checkbox label:before {
    width: 20px;
    height: 20px;
}

.custom-segment .ui.checkbox,
.tabs-info .ui.checkbox {
    line-height: 20px;
    width: 20px;
    min-height: 22px;
    padding: 0 !important;
}

.bank-list .ui.checkbox input:checked ~ .box:before,
.bank-list .ui.checkbox input:checked ~ label:before {
    background: #eca800 !important;
}

.bank-list .ui.checkbox input:checked ~ .box:after,
.bank-list .ui.checkbox input:checked ~ label:after {
    background-color: #eca800 !important;
    content: '\e800' !important;
    color: #ffffff !important;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bank-list {
    box-shadow: none !important;
}

/**CHECK BOX**/
.custom-segment .item-image.xs {
    height: 3rem;
}

.payment-success-icon {
    margin: 1rem 0 2rem;
}

.payment-success-icon .item-image {
    height: 6rem;
}

.segment-icon-bg i {
    font-size: 1rem;
    color: #ffffff;
}

.custom-segment > .row > .padded > div {
    padding-top: 0.5rem;
    padding-bottom: 0.3rem;
}

.segment-price {
    margin-right: 0.5rem;
    line-height: 1;
}

.segment-price > .title {
    font-weight: bold;
    font-size: 1.3rem;
}

.segment-price > .sub-title {
    font-size: 0.9rem;
}

.font-bold {
    font-weight: bold;
}

.item-monthly-list-segment > .segment {
    /*padding-top: 0;*/
    padding: 0 0.5rem 0.5rem;
}

.item-monthly-list-segment .segments {
    border-radius: 6px !important;
}

.custom-segment-space.active > .segment {
    border-radius: 6px 6px 0 0 !important;
}

.custom-segment-space.active .segment-details {
    border-radius: 0 6px 0 0 !important;
}

.item-monthly-list-title {
    position: relative;
    /*background: #303B46;*/
    background: rgba(48, 59, 70, 0.82);
    color: #ffffff;
    padding: 0.3rem 1rem;
    border-radius: 0 0 4px 4px;
}

.custom-segment.success .ui.checkbox,
.custom-segment.warning .ui.checkbox {
    opacity: 0.8;
}

.field-inline > div {
    padding-right: 1rem !important;
}

.field-inline > :last-child {
    padding-right: 0 !important;
}

.MessageTop {
    margin: 0 !important;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.custom-input {
    padding: 0 !important;
    text-indent: 1rem;
}

.absolute-bottom-middle {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    display: flex;
}

.picture-frame {
    width: 100%;
    height: auto;
}

.picture-frame img {
    max-width: 100%;
}

.block-after {
    position: relative;
}

.block-after:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.custom-segment-space {
    position: relative;
    margin-bottom: 0.7rem;
}

.custom-segment-space .segment {
    border: 0
}

.more-detail-btn {
    position: relative;
    /*background: #303B46;*/
    background: rgba(48, 59, 70, 0.82);
    color: #ffffff;
    padding: 0.2rem 1rem;
    border-radius: 4px 4px 0 0;
    font-size: 1rem;
    cursor: pointer;
    bottom: 17px;
}

.custom-segment-space.active .more-detail-btn .fa-chevron-down,
.more-detail-btn .fa-chevron-up {
    display: none;
}

.custom-segment-space.active .more-detail-btn .fa-chevron-up,
.more-detail-btn .fa-chevron-down {
    display: block;
}

.more-detail-btn:hover {
    background: rgba(15, 15, 16, 0.8);
}

.custom-segment-space.active .more-detail-btn {
    background-color: #E9E9EA;
    color: #303B46;
}

.more-detail {
    background-color: #E9E9EA;
    border-radius: 0 0 4px 4px;
    padding: 0.5rem;
    display: none;
}

.custom-segment-space.active .more-detail {
    display: block;
}

.tabs-title {
    display: flex;
    margin: 0 -0.25rem;
}

.tabs-title > div {
    width: 50%;
    padding: 0 0.25rem;
}

.tabs-title-btn {
    padding: 10px;
    background: #D7D7D9;
    border-radius: 6px 6px 0 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    letter-spacing: 1px;
}

.item.active .tabs-title-btn {
    background: #eca800;
    box-shadow: none;
    color: white
}

.arrow-btm-pos {
    position: absolute;
    left: 0;
    top: 80%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;

}

.item.active .arrow-btm-pos {
    top: 100%;
    opacity: 1;
    transition: all 0.3s;
}

.tabs-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #eca800 transparent transparent transparent;
}

.tabs-info {
    background: #fff;
    /*padding: 1rem;*/
    border-radius: 0 0 6px 6px;
    border-top: 2px solid #eca800;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.status-btn {
    background: #eca800 !important;
    color: #fff !important;
    font-weight: 100;
    border-radius: 6px !important;
    padding: 0.3rem 0.6rem !important;
    letter-spacing: 1px !important;
    display: inline-block !important;
}

.status-btn.sm {
    font-size: 12px;
}

.status-btn.danger {
    background: #db2828 !important;
}

.title-history {
    border-radius: 0 !important;
    background: #303B46;
    color: rgba(255, 255, 255, .9);
    font-weight: bold;
    padding: 1rem;
}

.title-history.white-bg {
    background: white;
}

.title-history.gray-bg,
.custom-segment.gray-bg {
    background: #EDF2F6;
    color: #000
}

.payment-list {
    margin: 0 !important;
}

.rc-time-picker-input {
    width: 100% !important;
    height: auto !important;
}

.rc-time-picker-clear {
    top: 0 !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rc-time-picker {
    width: 250px !important;
    max-width: 100% !important;
    display: block !important;
}

.payment-list .item {
    padding: 1rem !important;
}

.payment-list .custom-payment-method-item {
    padding-right: 1rem !important;
    padding-left: 2.7rem !important;
}

.payment-list.payment-history-list .item {
    background: #EDF2F6;
    margin-bottom: 0.2rem
}

.input-custom-bg {
    background: #f4f4f4 !important;
}

.download-btn {
    margin: 0.6rem 0 1rem;
}


.disabled-grey,
.disabled-grey:focus,
.disabled-grey:active,
.disabled-grey:hover {
    background: #a0a0a0 !important;
    border-color: #a0a0a0 !important;
    border-style: solid;
    border-width: 0;
    opacity: 1 !important;
}

.no-border {
    border: 0 !important;
}

.cursor-default,
.cursor-default label {
    cursor: default !important;
}

.email-box {
    background: white;
    padding: 3px 10px;
    border-radius: 4px;
    color: #000;
    -webkit-box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
}


.payment-success-icon svg {
    width: 100px;
    display: block;
    margin: 0 auto 0;
}
.payment-success-icon .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
}
.payment-success-icon .path.circle {
    -webkit-animation: dash 0.9s ease-in-out;
    animation: dash 0.9s ease-in-out;
}
.payment-success-icon .path.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
    animation: dash 0.9s 0.35s ease-in-out forwards;
}
.payment-success-icon .path.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
    animation: dash-check 0.9s 0.35s ease-in-out forwards;
}
.payment-success-icon p {
    text-align: center;
    margin: 20px 0 60px;
    font-size: 1.25em;
}
.payment-success-icon p.success {
    color: #73AF55;
}
.payment-success-icon p.error {
    color: #D06079;
}
@-webkit-keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
@-webkit-keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }
    100% {
        stroke-dashoffset: 900;
    }
}
@keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }
    100% {
        stroke-dashoffset: 900;
    }
}
