.header-bg{
    background: #fff;
    min-height: 5px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.header_logo{
    height: 60px;
}